<div class="promote-material globalsidebar">
  <div class="title">
    <h5>{{'label.promoting-material' | translate }}</h5>
  </div>


  <div class="materials" *ngIf="promoMaterialFlag; else nodata">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 material-wrapper" *ngIf="orgs.length > 0">
        <div class="p-material">
          <div class="promo-image">
            <img src="../../../assets/images/promo.png" alt="promo">
          </div>
          <div class="download-promo">
            <img style="height: 30px;" src="../../../assets/images/pdf.jpg" alt="pdf">
            <a href="{{orgs[0]?.acf?.promotional_materials_attachment.url}}" download="Org Profile.pdf"
              target="_blank"><span>{{'btn.download' | translate}}</span></a>
          </div>
        </div>
        <span class="m-name">Org Profile</span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 material-wrapper" *ngIf="brochures.length > 0">
        <div class="p-material">
          <div class="promo-image">
            <img src="../../../assets/images/promo.png" alt="promo">
          </div>
          <div class="download-promo">
            <img style="height: 40px;" src="../../../assets/images/pdf.jpg" alt="pdf">
            <a href="{{brochures[0]?.acf?.promotional_materials_attachment.url}}" download="Brochure.pdf"
              target="_blank"><span>{{'btn.download' | translate}}</span></a>
          </div>
        </div>
        <span class="m-name">Brochure</span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 material-wrapper" *ngIf="posters.length > 0">
        <div class="p-material">
          <div class="promo-image">
            <img src="../../../assets/images/promo.png" alt="promo">
          </div>
          <div class="download-promo">
            <img style="height: 30px;" src="../../../assets/images/pdf.jpg" alt="pdf">
            <a href="{{posters[0]?.acf?.promotional_materials_attachment.url}}" download="Poster.pdf"
              target="_blank"><span>{{'btn.download' | translate}}</span></a>
          </div>
        </div>
        <span class="m-name">Poster</span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 material-wrapper" *ngIf="flyers.length > 0">
        <div class="p-material">
          <div class="promo-image">
            <img src="../../../assets/images/promo.png" alt="promo">
          </div>
          <div class="download-promo">
            <img style="height: 30px;" src="../../../assets/images/pdf.jpg" alt="pdf">
            <a href="{{flyers[0]?.acf?.promotional_materials_attachment.url}}" download="Flyer.pdf"
              target="_blank"><span>{{'btn.download' | translate}}</span></a>
          </div>
        </div>
        <span class="m-name">Flyer</span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 material-wrapper" *ngIf="booklets.length > 0">
        <div class="p-material">
          <div class="promo-image">
            <img src="../../../assets/images/promo.png" alt="promo">
          </div>
          <div class="download-promo">
            <img style="height: 30px;" src="../../../assets/images/pdf.jpg" alt="pdf">
            <a href="{{booklets[0]?.acf?.promotional_materials_attachment.url}}" download="Booklet.pdf"
              target="_blank"><span>{{'btn.download' | translate}}</span></a>
          </div>
        </div>
        <span class="m-name">Booklet</span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 material-wrapper" *ngIf="newsletters.length > 0">
        <div class="p-material">
          <div class="promo-image">
            <img src="../../../assets/images/promo.png" alt="promo">
          </div>
          <div class="download-promo">
            <img style="height: 30px;" src="../../../assets/images/pdf.jpg" alt="pdf">
            <a [href]="newsletters[0].attachment" download="Newsletter.pdf"
              target="_blank"><span>{{'btn.download' | translate}}</span></a>
          </div>
        </div>
        <span class="m-name">Newsletter</span>
      </div>
    </div>
  </div>
  <ng-template #nodata>
    <div class="jumbotron no-data">
      <div [class.dot-falling]="loading"></div>
      <h4 *ngIf="!loading" class="text-center">{{ 'label.no-data' | translate }}</h4>
    </div>
  </ng-template>
</div>
