<!-- <router-outlet></router-outlet> -->
<!--The content below is only a placeholder and can be replaced.-->
<ngx-loading-bar [color]="282560"></ngx-loading-bar>
<div *ngIf="!chooseLang">
    <app-navbar></app-navbar>
</div>

<div class="custom-container"  *ngIf="!chooseLang">
  <div id="scroll" data-toggle="tooltip" data-placement="top" title="TOP">
    <i class="fas fa-angle-up"></i>
  </div>

  <nav class="navigation">
    <div class="full-path" *ngFor="let pt of currentPath; let i = index">
      <small class="fullpath" *ngIf="i !== 0 && i !== currentPath.length - 1">{{ pt }}</small>
      <i class="fas fa-chevron-right" *ngIf="i !== 0 && i !== currentPath.length - 1"></i>
      <small class="currentpath" *ngIf="i == currentPath.length - 1">{{ pt }}</small>
    </div>
  </nav>
</div>

<main [@fadeInOut]="getPage(o)">
  <router-outlet #o="outlet"></router-outlet>
</main>

<!-- <router-outlet [@fadeInOut]='true'></router-outlet> -->
<div  *ngIf="!chooseLang">
    <app-footer></app-footer>
</div>
