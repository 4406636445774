<div class="quick-links">
    <div class="custom-container">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="link-title">
                    <h4>{{ 'label.subscribe.title' | translate }}</h4>
                </div>
                <div class="links">
                    <p>{{ 'label.news-letter-subscribe' | translate }}</p>
                    <input type="email" name="email" id="email" [placeholder]="'label.email-address' | translate"
                        #useremail required [(ngModel)]="email" (input)="checkEmail()" />
                    <button [disabled]="!validEmail" (click)="subscribe(useremail.value)" class="subscribe-btn">
                        {{ 'label.subscribe' | translate }}
                    </button>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="link-title">
                    <h4>{{ 'menu.about-us' | translate }}</h4>
                </div>

                <div class="links">
                    <ul>
                        <li routerLink="/about-us">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'submenu.about-nsia' | translate }} </a>
                        </li>
                        <li routerLink="/about-us/structure">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{
                                'label.organization-structure' | translate | titlecase
                                }}</a>
                        </li>
                        <li routerLink="/contact-us">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'label.contact-us' | translate | titlecase }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="link-title">
                    <h4>{{ 'label.quick-links' | translate }}</h4>
                </div>
                <div class="links">
                    <ul>
                        <li (click)="updateServiceType('stats')">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'label.stats' | translate | titlecase }}</a>
                        </li>
                        <li (click)="updateServiceType('prs')">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'submenu.provincial-s' | translate | titlecase }}</a>
                        </li>
                        <li routerLink="/e-tazkira-centers">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'label.tazkira-enrollement' | translate | titlecase }}</a>
                        </li>
                        <!-- <li>
              <i class="fas fa-chevron-right"></i>
              <a>{{'label.laws' | translate}}</a></li> -->
                        <li routerLink="/opportunities/procurements">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'submenu.procurement' | translate | titlecase }}</a>
                        </li>
                        <li routerLink="/opportunities/jobs">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'label.jobs' | translate | titlecase }}</a>
                        </li>
                        <li routerLink="/info/access-info">
                            <i class="fas fa-chevron-right"></i>
                            <a>{{ 'label.access-info' | translate | titlecase }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="link-title">
                    <h4>{{ 'menu.forms' | translate }}</h4>
                </div>
                <div class="links" *ngIf="forms.length > 0; else noforms">
                    <ul>
                        <li *ngFor="let fm of forms">
                            <i class="fas fa-chevron-right"></i>
                            <a [href]="fm.url" target="_blank">{{ fm.title }}</a>
                        </li>
                    </ul>
                </div>
                <ng-template #noforms>
                    <div class="jumbotron no-data">
                        <div [class.dot-falling]="loading"></div>
                        <h4 *ngIf="!loading" class="text-center">
                            {{ 'label.no-data' | translate }}
                        </h4>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div class="footer">
    <div class="custom-container">
        <div class="contents-wrapper">
            <div class="contents">
                <div class="footer-logo">
                    <img src="../../../assets/logo/flag-design.png" alt="flat logo" />
                </div>

                <div class="copy-right">
                    <span>
                        <b>
                            {{ 'label.copy-right' | translate: { year: year } }}
                        </b>
                    </span>
                </div>

                <div class="social-media">
                    <div>
                        <div class="s-media">
                            <a href="https://www.instagram.com/nsia_afghanistan/" target="_blank">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </div>
                        <div class="s-media">
                            <a href="https://www.youtube.com/channel/UCO4p9zdLgTJrYHHd-3M3hjQ?view_as=subscriber"
                                target="_blank">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </div>
                        <div class="s-media">
                            <a href="https://www.linkedin.com/company/67711199" target="_blank">
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </div>
                        <div class="s-media">
                            <a href="https://twitter.com/NSIAGOVAFG" target="_blank">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </div>
                        <div class="s-media">
                            <a href="https://www.facebook.com/NSIA.GOV.AF" target="_blank">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>