<div class="navbar-wrapper">
    <div class="header">
        <div class="custom-container">
            <div class="row">
                <div class="col-lg-6 col-md-12 logos">
                    <div class="logo-wrapper">
                        <div class="nsia-logo">
                            <img src="../../../assets/logo/amended-logo final final-01.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="header-details">
                        <div class="contact-details">
                            <div class="c-item">
                                <p><i class="fas fa-clock r-icon"></i>8:00AM - 4:00PM</p>
                            </div>
                            <div class="c-item">
                                <p routerLink="/contact-us">
                                    <i class="fas fa-phone contact r-icon"></i>
                                    {{ "label.contact-us" | translate }}
                                </p>
                            </div>
                            <!-- <div class="c-item">
                <p> <i class="fas fa-clock"></i> {{ 'label.working-hours' | translate }}</p>
              </div> -->
                            <div class="c-item" data-toggle="modal" data-target="#mapModal">
                                <p>
                                    <i class="fas fa-map-marker-alt r-icon"></i>
                                    {{ "label.head-office" | translate }}
                                </p>
                            </div>
                        </div>

                        <div class="website-tools">
                            <div class="tools-wrapper">
                                <div class="search-input c-item">
                                    <div class="wrapper">
                                        <input type="text" placeholder="{{ 'label.search-website' | translate }}"
                                            [(ngModel)]="sText" class="search-box" #searchText
                                            (keyup)="showSearchResults($event, searchText.value)"
                                            (focus)="showSearchDiv(searchText.value)" />
                                        <!-- <input type="text" placeholder="{{'label.search-website' | translate }}"
                    class="search-box" #searchText > -->
                                        <i class="fas fa-search"></i>
                                    </div>
                                </div>
                                <div class="change-language c-item">
                                    <select name="lang-change" id="lang-change" class="form-control custom-form-control"
                                        #languageChange1 (change)="useLanguage(languageChange1)">
                                        <option value="en">{{ "lang.english" | translate }}</option>
                                        <option value="fa">{{ "lang.dari" | translate }}</option>
                                        <option value="ps">{{ "lang.pashto" | translate }}</option>
                                    </select>
                                    <i class="fas fa-chevron-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-light" id="navbar">
        <div class="custom-container">
            <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsable-nav" aria-controls="collapsable-nav"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
            <!-- Custom Responsive navbar -->
            <div class="responsive-navbar">
                <button class="resp-toggler" (click)="toggleRespNav()">
                    <i class="fas fa-bars"></i>
                </button>
                <div class="tls">
                    <div class="web-tls">
                        <div class="search-input c-item">
                            <div class="wrapper">
                                <input type="text" placeholder="{{ 'label.search-website' | translate }}"
                                    [(ngModel)]="sText" class="search-box" #searchTextRes
                                    (keyup)="showSearchResults($event, searchTextRes.value)"
                                    (focus)="showSearchDiv(searchText.value)" />

                                <!-- <input type="text" placeholder="{{'label.search-website' | translate }}" class="search-box"> -->
                                <i class="fas fa-search"></i>
                            </div>
                        </div>
                        <div class="change-language c-item">
                            <select name="lang-change" id="lang-change2" class="form-control custom-form-control"
                                #languageChange2 (change)="useLanguage(languageChange2)">
                                <option value="en">{{ "lang.english" | translate }}</option>
                                <option value="fa">{{ "lang.dari" | translate }}</option>
                                <option value="ps">{{ "lang.pashto" | translate }}</option>
                            </select>
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="responsive-nav" id="responsive-nav">
                <div class="r-menu">
                    <div class="r-menu-item">
                        <p routerLink="/home"><i class="fas fa-home"></i></p>
                    </div>
                    <div class="r-menu-item">
                        <p routerLink="/about-us">
                            {{ "menu.about-us" | translate }}
                        </p>
                        <ul>
                            <li routerLink="/about-us/about-nsia">
                                {{ "submenu.about-nsia" | translate }}
                            </li>
                            <li routerLink="/about-us/vision-mission-objectives">
                                {{ "submenu.vision-mission-objectives" | translate }}
                            </li>
                            <li routerLink="/about-us/main-duties">
                                {{ "submenu.main-duties" | translate }}
                            </li>
                            <li routerLink="/about-us/structure">
                                {{ "submenu.structure" | translate }}
                            </li>
                            <li routerLink="/about-us/deputy-director-generals">
                                {{ "submenu.deputy-director-generals" | translate }}
                            </li>
                            <li routerLink="/about-us/general-director-message">
                                {{ "submenu.deputy-director-generals" | translate }}
                            </li>
                            <li routerLink="/about-us/directorates">
                                {{ "submenu.directorates" | translate }}
                            </li>
                           <!-- <li routerLink="/about-us/provincial-offices">
                                {{ "submenu.provincial-offices" | translate }}
                            </li>-->
                            <li routerLink="/about-us/activity-achievments">
                                {{ "submenu.achievments" | translate }}
                            </li>
                            <li routerLink="/about-us/board-directors">
                                {{ "submenu.board-directors" | translate }}
                            </li>
                            <!-- <li routerLink="/about-us/policies">{{'submenu.policies' | translate}}</li> -->
                            <!-- <li routerLink="/about-us/biographies">
                {{ "submenu.biographies" | translate }}
              </li> -->
                        </ul>
                    </div>
                    <div class="r-menu-item">
                        <p routerLink="/services">
                            {{ "menu.services" | translate }}
                        </p>
                        <ul>
                            <li routerLink="/services" (click)="updateServiceType('stats')">
                                {{ "submenu.statistics" | translate }}
                            </li>
                            <li routerLink="/services" (click)="updateServiceType('nid')">
                                {{ "submenu.nid" | translate }}
                            </li>
                            <li routerLink="/services" (click)="updateServiceType('gis')">
                                {{ "submenu.gis" | translate }}
                            </li>
                            <li routerLink="/services" (click)="updateServiceType('sdu')">
                                {{ "submenu.isd" | translate }}
                            </li>
                            <li routerLink="/services" (click)="updateServiceType('prs')">
                                {{ "submenu.provincial-s" | translate }}
                            </li>
                            <li routerLink="/services/imageries-provision-questionnaira">
                                {{ "submenu.imageries-provision-questionnaira" | translate }}
                            </li>
                        </ul>
                    </div>
                    <div class="r-menu-item">
                        <p routerLink="/library">
                            {{ "menu.library" | translate }}
                        </p>
                        <ul>
                            <li routerLink="/library" (click)="updateLibraryType('books')">
                                {{ "btn.books" | translate }}
                            </li>
                            <li routerLink="/library" (click)="updateLibraryType('quarterly')">
                                {{ "btn.quarterly" | translate }}
                            </li>
                            <li routerLink="/library" (click)="updateLibraryType('monthly')">
                                {{ "btn.monthly" | translate }}
                            </li>
                            <!-- <li routerLink="/library" (click)="updateLibraryType('surveys')">
              {{ "btn.surveys" | translate }}
              </li> -->
                            <li routerLink="/library" (click)="updateLibraryType('reports')">
                                {{ "btn.reports" | translate }}
                            </li>
                            <li routerLink="/library" (click)="updateLibraryType('periodicals')">
                                {{ "btn.periodicals" | translate }}
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="r-menu-item">
            <p routerLink="/access-info">
              {{ 'menu.access-info' | translate }}</p>
            <ul>
              <li routerLink="access-info/guidelines">Guidelines</li>
              <li routerLink="access-info/statistics">Statistics</li>
              <li routerLink="access-info/nid">National Identity</li>
              <li routerLink="access-info/regualtions">Regulations</li>
              <li routerLink="access-info/policies">Policies</li>
            </ul>
          </div> -->
                    <div class="r-menu-item">
                        <p routerLink="/info/laws-documents">
                            {{ "menu.informations" | translate }}
                        </p>
                        <ul>
                            <!-- <li routerLink="/media-room/event">{{'submenu.events' | translate}}</li> -->
                            <li routerLink="/info/laws">
                                {{ "submenu.laws" | translate }}
                            </li>
                            <li routerLink="/info/policies">
                                {{ "submenu.policies" | translate }}
                            </li>
                            <li routerLink="/info/annual-plan">
                                {{ "submenu.information.hub.plans" | translate }}
                            </li>

                            <li routerLink="/info/annual-report">
                                {{ "submenu.information.hub.reports" | translate }}
                            </li>
                            <li routerLink="info/financial-documents">
                                {{ "submenu.financial-documents" | translate }}
                            </li>
                           <li routerLink="info/procurment-documents">
                                {{ "submenu.procurment-documents" | translate }}
                            </li>
                            <!--<li routerLink="info/audit-report">
                                {{ "submenu.audit-report" | translate }}
                            </li>
                            <li routerLink="info/surveys-projects">
                                {{ "submenu.information.hub.surveys" | translate }}
                            </li>-->
                            <li routerLink="info/contracts-mous">
                                {{ "submenu.information.hub.contracts" | translate }}
                            </li>
                            <li routerLink="info/access-info">
                                {{ "submenu.access-info" | translate }}
                            </li>

                            <!-- <li routerLink="media-room/poster">Poster</li>
              <li routerLink="media-room/booklet">Booklet</li>
              <li routerLink="media-room/brochures">Brochures</li> -->
                        </ul>
                    </div>
                    <div class="r-menu-item">
                        <p routerLink="/media-room/news-updates">
                            {{ "menu.media" | translate }}
                        </p>
                        <ul>
                            <!-- <li routerLink="/media-room/event">{{'submenu.events' | translate}}</li> -->
                            <li routerLink="/media-room/news-updates">
                                {{ "submenu.news-updates" | translate }}
                            </li>

                            <li routerLink="/media-room/press-release">
                                {{ "submenu.press-release" | translate }}
                            </li>
                            <li routerLink="/media-room/important-announcements">
                                {{ "submenu.important-announcements" | translate }}
                            </li>
                            <li routerLink="media-room/editorials">
                                {{ "submenu.editorials" | translate }}
                            </li>
                            <li routerLink="media-room/electronic-magazine">
                                {{ "submenu.electronic-magazine" | translate }}
                            </li>
                           <!-- <li routerLink="media-room/pictures">
                                {{ "submenu.pictures" | translate }}
                            </li>-->
                            <li routerLink="media-room/videos">
                                {{ "submenu.videos" | translate }}
                            </li>
                            <li routerLink="media-room/infographics">
                                {{ "submenu.infographics" | translate }}
                            </li>
                           <!--<li routerLink="media-room/communication-priorities">
                                {{ "submenu.communication-priorities" | translate }}
                            </li>-->

                            <!-- <li routerLink="media-room/poster">Poster</li>
              <li routerLink="media-room/booklet">Booklet</li>
              <li routerLink="media-room/brochures">Brochures</li> -->
                        </ul>
                    </div>


                    <div class="r-menu-item">
                        <p routerLink="/opportunities">
                            {{ "menu.opportunities" | translate }}
                        </p>
                        <ul>
                            <li routerLink="/opportunities/jobs">
                                {{ "submenu.jobs" | translate }}
                            </li>
                           <li routerLink="/opportunities/procurements">
                                {{ "submenu.procurement" | translate }}
                            </li>
                            <!-- <li routerLink="opportunities/tenders">Tenders</li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="collapse navbar-collapse" id="collapsable-nav">
                <ul class="navbar-nav mt-2 mt-lg-0">
                    <li class="nav-item" id="home">
                        <a href="" routerLink="/home">
                            <i class="fas fa-home"></i>
                        </a>
                    </li>
                    <div class="seperator">
                        <div></div>
                    </div>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" routerLink="/about-us">
                            {{ "menu.about-us" | translate }}
                        </a>
                        <i class="fas fa-chevron-down"></i>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/about-nsia">{{ "submenu.about-nsia" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/vision-mission-objectives">{{ "submenu.vision-mission-objectives" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/main-duties">{{ "submenu.main-duties" | translate }}</a>

                            <a href="" class="dropdown-item"
                                routerLink="/about-us/structure">{{ "submenu.structure" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/deputy-director-generals">{{ "submenu.deputy-director-generals" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/general-director-message">{{ "submenu.general-director-message" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/directorates">{{ "submenu.directorates" | translate }}</a>
                          <!-- <a href="" class="dropdown-item"
                                routerLink="/about-us/provincial-offices">{{ "submenu.provincial-offices" | translate }}</a>-->
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/activity-achievments">{{ "submenu.achievments" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/about-us/board-directors">{{ "submenu.board-directors" | translate }}</a>
                            <!-- <a href="" class="dropdown-item" routerLink="/about-us/rules-policies">{{'submenu.policies' | translate}}</a> -->
                            <!-- <a
              href=""
              class="dropdown-item"
              routerLink="/about-us/biographies"
              >{{ "submenu.biographies" | translate }}</a
            > -->
                        </div>
                    </li>
                    <div class="seperator">
                        <div></div>
                    </div>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" routerLink="/services">
                            {{ "menu.services" | translate }}
                        </a>
                        <i class="fas fa-chevron-down"></i>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href="" class="dropdown-item" routerLink="/services"
                                (click)="updateServiceType('stats')">{{ "submenu.statistics" | translate }}</a>
                            <a href="" class="dropdown-item" routerLink="/services"
                                (click)="updateServiceType('nid')">{{ "submenu.nid" | translate }}</a>
                            <a href="" class="dropdown-item" routerLink="/services"
                                (click)="updateServiceType('gis')">{{ "submenu.gis" | translate }}</a>
                            <a href="" class="dropdown-item" routerLink="/services"
                                (click)="updateServiceType('sdu')">{{ "submenu.isd" | translate }}</a>
                            <a href="" class="dropdown-item" routerLink="/services"
                                (click)="updateServiceType('prs')">{{ "submenu.provincial-s" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/services/imageries-provision-questionnaira">{{ "submenu.imageries-provision-questionnaira" | translate }}</a>
                        </div>
                    </li>
                    <div class="seperator">
                        <div></div>
                    </div>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" routerLink="/library">
                            {{ "menu.library" | translate }}
                        </a>
                        <i class="fas fa-chevron-down"></i>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href="" class="dropdown-item" routerLink="/library"
                                (click)="updateLibraryType('books')">{{ "btn.books" | translate }}</a>
                            <a href="" class="dropdown-item" routerLink="/library"
                                (click)="updateLibraryType('quarterly')">{{ "btn.quarterly" | translate }}</a>
                            <a href="" class="dropdown-item" routerLink="/library"
                                (click)="updateLibraryType('monthly')">{{ "btn.monthly" | translate }}</a>
                            <!-- <a href="" class="dropdown-item" routerLink="/library"
                (click)="updateLibraryType('surveys')">{{ "btn.surveys" | translate }}</a> -->
                            <a href="" class="dropdown-item" routerLink="/library"
                                (click)="updateLibraryType('reports')">{{ "btn.reports" | translate }}</a>
                            <a href="" class="dropdown-item" routerLink="/library"
                                (click)="updateLibraryType('periodicals')">{{ "btn.periodicals" | translate }}</a>
                        </div>
                    </li>
                    <div class="seperator">
                        <div></div>
                    </div>
                    <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" routerLink="/access-info">
              {{ 'menu.access-info' | translate }}
            </a>
            <i class="fas fa-chevron-down"></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a href="" class="dropdown-item" routerLink="/access-info/guidelines">Guidelines</a>
              <a href="" class="dropdown-item" routerLink="/access-info/statistics">Statistics</a>
              <a href="" class="dropdown-item" routerLink="/access-info/nid">National Identity</a>
              <a href="" class="dropdown-item" routerLink="/access-info/regualtions">Regulations</a>
              <a href="" class="dropdown-item" routerLink="/access-info/policies">Policies</a>
            </div>
          </li>
          <div class="seperator">
            <div></div>
          </div> -->
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" routerLink="/info/laws">
                            {{ "menu.informations" | translate }}
                        </a>
                        <i class="fas fa-chevron-down"></i>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <!-- <a href="" class="dropdown-item" routerLink="/media-room/event">{{'submenu.events' | translate}}</a> -->
                            <a href="" class="dropdown-item"
                                routerLink="/info/laws-documents">{{ "submenu.laws" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/info/policies">{{ "submenu.policies" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/info/annual-plan">{{ "submenu.information.hub.plans" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/info/annual-report">{{ "submenu.information.hub.reports" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/info/financial-documents">{{ "submenu.financial-documents" | translate }}
                            </a>
                            <a href="" class="dropdown-item"
                                routerLink="/info/procurment-documents">{{ "submenu.procurment-documents" | translate }}
                            </a>
                            <!--<a href="" class="dropdown-item"
                                routerLink="/info/audit-report">{{ "submenu.audit-report" | translate }}
                            </a>
                            <a href="" class="dropdown-item"
                                routerLink="/info/surveys-projects">{{ "submenu.information.hub.surveys" | translate }}</a>-->
                            <a href="" class="dropdown-item"
                                routerLink="/info/contracts-mous">{{ "submenu.information.hub.contracts" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/info/access-info">{{ "submenu.access-info" | translate }}
                            </a>
                            <!--<a href="" class="dropdown-item" routerLink="/media-room/poster">Poster</a>
              <a href="" class="dropdown-item" routerLink="/media-room/booklet">Booklet</a>
              <a href="" class="dropdown-item" routerLink="/media-room/brochures">Brochures</a> -->
                        </div>
                    </li>
                    <div class="seperator">
                        <div></div>
                    </div>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" routerLink="/media-room/news-updates">
                            {{ "menu.media" | translate }}
                        </a>
                        <i class="fas fa-chevron-down"></i>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <!-- <a href="" class="dropdown-item" routerLink="/media-room/event">{{'submenu.events' | translate}}</a> -->
                            <a href="" class="dropdown-item"
                                routerLink="/media-room/news-updates">{{ "submenu.news-updates" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/media-room/press-release">{{ "submenu.press-release" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/media-room/important-announcements">{{ "submenu.important-announcements" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/media-room/editorials">{{ "submenu.editorials" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/media-room/electronic-magazine">{{ "submenu.electronic-magazine" | translate }}</a>
                           <!-- <a href="" class="dropdown-item"
                                routerLink="/media-room/pictures">{{ "submenu.pictures" | translate }}</a>-->
                            <a href="" class="dropdown-item"
                                routerLink="/media-room/videos">{{ "submenu.videos" | translate }}</a>
                            <a href="" class="dropdown-item"
                                routerLink="/media-room/infographics">{{ "submenu.infographics" | translate }}</a>
                           <!-- <a href="" class="dropdown-item"
                                routerLink="/media-room/communication-priorities">{{ "submenu.communication-priorities" | translate }}</a>-->
                            <!--<a href="" class="dropdown-item" routerLink="/media-room/poster">Poster</a>
              <a href="" class="dropdown-item" routerLink="/media-room/booklet">Booklet</a>
              <a href="" class="dropdown-item" routerLink="/media-room/brochures">Brochures</a> -->
                        </div>
                    </li>
                    <div class="seperator">
                        <div></div>
                    </div>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" routerLink="/opportunities/jobs">
                            {{ "menu.opportunities" | translate }}
                        </a>
                        <i class="fas fa-chevron-down"></i>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href="" class="dropdown-item"
                                routerLink="/opportunities/jobs">{{ "submenu.jobs" | translate }}</a>
                            <!-- <a href="" class="dropdown-item" routerLink="/opportunities/procurements">Procurements</a> -->
                          <a href="" class="dropdown-item"
                                routerLink="/opportunities/procurements">{{ "submenu.procurement" | translate }}</a>
                        </div>
                    </li>
                    <div class="seperator">
                        <div></div>
                    </div>
                </ul>
                <!-- <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form> -->
            </div>
        </div>
    </nav>

    <div class="search-result-wrapper" id="search-result-wrapper">
        <i class="fas fa-times" (click)="hideSearchResults()"></i>
        <div class="search-results">
            <div class="result-links result">
                <h4 class="search-item-title">Links</h4>
                <ul>
                    <li *ngFor="let ln of quickLinks | searchFilter: sText" (click)="gotoLink(ln)">
                        <i class="fas fa-chevron-right"></i>
                        {{ ln[linkTitle] }}
                    </li>
                </ul>
            </div>
            <div class="result-services result" *ngIf="searchCategories.services.length > 0">
                <h4 class="search-item-title">Services</h4>
                <ul>
                    <li *ngFor="let sr of searchCategories.services" (click)="showServiceDetails(sr)">
                        <i class="fas fa-chevron-right"></i>
                        <span [innerHTML]="sr.title.rendered"></span>
                    </li>
                </ul>
            </div>

            <div class="result-services result" *ngIf="searchCategories.news.length > 0">
                <h4 class="search-item-title">News and Updates</h4>
                <ul>
                    <li *ngFor="let nw of searchCategories.news" (click)="followLink('/media-room/media', nw.id)">
                        <i class="fas fa-chevron-right"></i>
                        <span [innerHTML]="nw.title.rendered"></span>
                    </li>
                </ul>
            </div>

            <div class="result-services result" *ngIf="searchCategories.biographies.length > 0">
                <h4 class="search-item-title">Biographies</h4>
                <ul>
                    <li *ngFor="let bi of searchCategories.biographies">
                        <i class="fas fa-chevron-right"></i>
                        <span [innerHTML]="bi.title.rendered"></span>
                    </li>
                </ul>
            </div>

            <div class="result-attachments result" *ngIf="searchCategories.attachments.length > 0">
                <h4 class="search-item-title">Attachments</h4>
                <ul *ngIf="linksArray">
                    <li *ngFor="let at of searchCategories.attachments">
                        <i class="fas fa-chevron-right"></i>
                        <span [innerHTML]="at"></span>
                    </li>
                </ul>
            </div>

            <div class="result-attachments result" *ngIf="
          searchCategories.procurements.length > 0 ||
          searchCategories.jobs.length > 0
        ">
                <h4 class="search-item-title">Opportunities</h4>
                <div class="sr-jobs" *ngIf="searchCategories.jobs.length > 0">
                    <h5>Jobs</h5>
                    <ul>
                        <li *ngFor="let jb of searchCategories.jobs" (click)="followLink('/opportunities/job', jb.id)">
                            <i class="fas fa-chevron-right"></i>
                            <span [innerHTML]="jb.title.rendered"></span>
                        </li>
                    </ul>
                </div>
                <div class="sr-procurements" *ngIf="searchCategories.procurements.length > 0">
                    <h5>Procurements</h5>
                    <ul>
                        <li *ngFor="let prc of searchCategories.procurements"
                            (click)="followLink('/opportunities/tender', prc.id)">
                            <i class="fas fa-chevron-right"></i>
                            <span [innerHTML]="prc.title.rendered"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal for google map -->
<!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade " id="mapModal" tabindex="-1" role="dialog" aria-labelledby="mapModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div id="map" #map></div>
                <div class="address">
                    <h6>{{ "label.nsia-address" | translate }}</h6>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ "label.close" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal  -->
<div class="modal fade " id="serviceModal" tabindex="-1" role="dialog" aria-labelledby="serviceModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="service-details" [innerHTML]="serviceDetails"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>