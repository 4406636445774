<div>
    <img src="../../assets/images/lang.jpg" id="bg">
    <div class="center">
        <div class="nsia-log">
            <img src="../../assets/logo/amended-logo final final-01.svg" alt="Logo">
        </div>
        <div class="lang-buttons">
            <div (click)="detectLanguage($event.target)" id="en" class="lan">English</div>
            <div (click)="detectLanguage($event.target)" id="fa" class="lan">دری</div>
            <div (click)="detectLanguage($event.target)" id="ps" class="lan">پښتو</div>
        </div>
    </div>
    <footer class="footer">
        © {{ year }}, All rights reserved, National Statistics and Information Authority
    </footer>
</div>