<div class="custom-container">
  <div class="not-found">
    <div class="not-found-wrapper">
      <div class="icon-wrapper">
        <img src="../../assets/images/404.svg" alt="" />
      </div>
      <div class="details">
        <h1 class="error-message">404</h1>
        <p>{{ "error.message.404" | translate }}</p>
      </div>
      <div class="btns">
        <button class="go-back c-btn-outline-blue" (click)="goBack()">
          {{ "back.message" | translate }}
        </button>
        <button class="go-home c-btn-outline-blue" (click)="goHome()">
          {{ "menu.home" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
