import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import * as $ from "jquery";
import * as moment from "jalali-moment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  nodeapi = "https://nsia.gov.af:8443:6001/api/";
  // For accessing the real server
  api = "https://nsia.gov.af:8443/index.php/wp-json/wp/v2/";
  language = "en";
  serviceType = "stats";
  redirectPath = "home";
  sliderDirection;
  years = [];

  /**
   * The following code establishes a connection between navbar component and service-home component
   * i.e the changes in navbar cmp are detected in service home cmp
   */

  callToServiceMethodSource = new Subject<any>();

  serviceCmpMethodCalled$ = this.callToServiceMethodSource.asObservable();

  constructor(private http: HttpClient) {}

  callServiceCmpMethod() {
    this.callToServiceMethodSource.next();
  }

  getNsiaText() {
    return this.http.get(this.api + "posts", {
      params: {
        "filter[category_name]": "nsia_services",
        lang: this.language,
        fields: "content.rendered",
      },
    });
  }

  getNumberOfETazkiraApplicaations() {
    let lang = this.language;
    if (this.language == "ps") {
      lang = "fa";
    }
    return this.http.get(
      "https://www.login.nid.nsia.gov.af/api/applicationsCount",
      {
        params: {
          lang: lang,
        },
      }
    );
  }

  getPosts() {
    return this.http.get(this.api, {
      params: {
        lang: this.language,
        per_page: "1",
      },
    });
  }

  getInitialStats(customP: Array<string>, catName) {
    return this.http.get(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        per_page: "4",
        orderby: "date",
        fields: customP.join(","),
      },
    });
  }

  getCarouselSlides(customP: Array<string>, catName) {
    return this.http.get<Array<object>>(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        fields: customP.join(","),
      },
    });
  }

  getBusinessData(customP, catName) {
    return this.http.get<Array<any>>(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        fields: customP.join(","),
      },
    });
  }
  getETazkiraCenters(customP) {
    return this.http.get<Array<any>>(this.api + "posts", {
      params: {
        "filter[category_name]": "e-tazkera-centers",
        lang: this.language,
        fields: customP.join(","),
      },
    });
  }

  getCardsData(customP, catName, perPage) {
    return this.http.get(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        per_page: perPage,
        fields: customP.join(","),
        orederby: "date",
      },
    });
  }

  registerUserEmail(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(this.api + "users/register", user, httpOptions);
  }
  addContactData(contactData) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      this.nodeapi + "contacts/add",
      contactData,
      httpOptions
    );
  }

  getPostDetails(id, customP) {
    return this.http.get<Array<any>>(this.api + "posts", {
      params: {
        lang: this.language,
        "filter[p]": id,
        fields: customP.join(","),
      },
    });
  }
  /**
   * The following two methods are related to the promotional materials
   */

  getMediaData(customP, catName) {
    return this.http.get<Array<any>>(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        fields: customP.join(","),
        orederby: "date",
      },
    });
  }
  getContactData(customP, catName) {
    return this.http.get<Array<any>>(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        fields: customP.join(","),
        orederby: "date",
      },
    });
  }

  getNewsData(customP, catName, perPage) {
    return this.http.get(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        per_page: perPage,
        fields: customP.join(","),
        orederby: "date",
      },
    });
  }

  htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, "") : "";
  }

  getForms(customP, catName, perPage) {
    return this.http.get(this.api + "posts", {
      params: {
        "filter[category_name]": catName,
        lang: this.language,
        per_page: perPage,
        fields: customP.join(","),
      },
    });
  }

  styleDetailsLink(data) {
    let contentRendered;
    if (data.hasOwnProperty("content")) {
      contentRendered = data.content.rendered;

      // wrap contents in a div first
      contentRendered = "<div>" + contentRendered + "</div>";

      const details = $.parseHTML(contentRendered);
      //console.log('details before', $(details).html());
      if ($(details).has("a")) {
        $(details).find("a").attr("target", "_blank");
        $(details).find("a").wrap('<div class="attachment" ></div>');

        $(details)
          .find("a")
          .each((i, val) => {
            const fileExtArray = $(val).attr("href").split(".");
            const fileExt = fileExtArray[fileExtArray.length - 1];
            // console.log('extension is : ', fileExt);

            let fileIcon = '<i class="fas fa-file-alt"></i>';
            if (fileExt === "xlsx" || fileExt === "xls") {
              fileIcon = '<i class="fas fa-file-excel"></i>';
            }
            if (fileExt === "pdf") {
              fileIcon = '<i class="fas fa-file-pdf"></i>';
            }
            $(val).parent().prepend(fileIcon);
          });
      }

      data.content.rendered = $(details).html();
    }
    // console.log('details are done', data);

    return data;
  }
  toggleNavbar() {
    const navWidth = (
      document.getElementsByClassName("sidebar-nav")[0] as HTMLElement
    ).style.width;
    // console.log('navWidth: ', navWidth);
    let rtl = false;

    if ($("body").hasClass("rtl")) {
      rtl = true;
    }

    if (navWidth === "0px") {
      (
        document.getElementsByClassName("sidebar-nav")[0] as HTMLElement
      ).style.width = "19vw";
      if (rtl) {
        (
          document.getElementsByClassName("sidebar-toggler")[0] as HTMLElement
        ).style.marginRight = "19vw";
      } else {
        (
          document.getElementsByClassName("sidebar-toggler")[0] as HTMLElement
        ).style.marginLeft = "19vw";
      }
      $(".sidebar-toggler i")
        .removeClass("fa-chevron-right")
        .addClass("fa-chevron-left");
    } else {
      (
        document.getElementsByClassName("sidebar-nav")[0] as HTMLElement
      ).style.width = "0px";
      if (rtl) {
        (
          document.getElementsByClassName("sidebar-toggler")[0] as HTMLElement
        ).style.marginRight = "0px";
      } else {
        (
          document.getElementsByClassName("sidebar-toggler")[0] as HTMLElement
        ).style.marginLeft = "0px";
      }
      $(".sidebar-toggler i")
        .removeClass("fa-chevron-left")
        .addClass("fa-chevron-right");
    }
  }

  public getYears() {
    const currentYear = moment().locale("fa").format("YYYY");
    const minYear = Number(currentYear) - 5;
    for (let year = minYear + 1; year <= Number(currentYear); year++) {
      this.years.push(year);
    }
    return this.years;
  }
}
